<template>
  <div class="section-container my-5">
    <div class="text-center" id="faq">
      <img src="@/assets/img/shared/question-blue-icon.svg" alt="Blue question" />
      <p class="title text-blue mt-3">
        Preguntas frecuentes
      </p>
    </div>

    <div class="questions-container">
      <div class="accordion" id="accordionQuestions">
        <!-- Q 1 - Cuáles son las condiciones de servicio? -->
        <div class="single-question">
          <div class="header" id="headingOne">
            <button 
              class="btn btn-link btn-block text-left d-flex justify-content-between align-items-center question-button" 
              @click="questionToggle(1)"
              >
              <p class="question pr-3">
                ¿Cuáles son las condiciones de servicio?
              </p>
              <ArrowToggleButton width="19" height="12" :isCollapsed="questions.indexOf(1) !== -1" />
            </button>
          </div>
          <div class="collapse question-text" :class="{'show': questions.indexOf(1) !== -1}">
            <p class="text-black">
              <span class="text-green bold-text pr-2">✓</span> 
              Vehículo año 2006 en adelante y libre de adeudos
              <br />
              <span class="text-green bold-text pr-2">✓</span> 
              Vehículo a nombre del solicitante.
              <br />
              <span class="text-green bold-text pr-2">✓</span> 
              Ingresos mínimos de $9,000 MXN.
              <br />
              <span class="text-green bold-text pr-2">✓</span>
              Vehículo ubicado en CDMX, Estado de México, Morelos, Querétaro, Puebla, Guadalajara, Zapopan, Tonalá, San Pedro Tlaquepaque, Veracruz e Hidalgo.
              <br />
            </p>
          </div>
        </div>
        <!-- Q 2 - ¿Cuáles son mis beneficios como cliente? -->
        <div class="single-question">
          <div class="header" id="headingTwo">
            <button 
              class="btn btn-block btn-link text-left d-flex justify-content-between align-items-center question-button" 
              @click="questionToggle(2)"
              >
              <p class="question pr-3">
                ¿Cuáles son mis beneficios como cliente?
              </p>
              <ArrowToggleButton width="19" height="12" :isCollapsed="questions.indexOf(2) !== -1" toggle="collapseTwo"/>
            </button>
          </div>
          <div :class="{'show': questions.indexOf(2) !== -1}" class="collapse question-text" >
            <p>
              En Ibancar nos esforzamos por ofrecer el mejor servicio financiero a nuestros clientes en México. Nuestro proceso de solicitud es en línea, lo que significa que no requerimos copias de Ilaves, visitas al taller ni trámites notariales complicados. En tan sólo 48 horas, puedes obtener el préstamo que necesitas. Y lo más importante, el coche sigue siendo tuyo y puedes seguir manejandolo.
            </p>
            <p>
              Además, asignamos un ejecutivo personal para brindarte apoyo individualizado durante todo el proceso de solicitud. En Ibancar, flexibilizamos las condiciones y facilitamos el acceso a préstamos a personas con Buró de Crédito negativo, entre otras ventajas que nos distinguen de otras empresas.
            </p>
            <p>
              Además tu préstamo en Ibancar incluye un paquete de servicios útiles e interesantes que protegen tu auto y patrimonio.
            </p>
            <div class="text-center p-3">
              <button
                class="btn-orange"
                @click="goTo('/como-funciona', '#ventajas')"
              >
                Ver todas las ventajas
              </button>
            </div>
          </div>
        </div>
        <!-- Q 3 - ¿Qué documentación tengo que enviar? -->
        <div class="single-question">
          <div class="header" id="headingThree">
            <button 
              class="btn btn-block btn-link text-left d-flex justify-content-between align-items-center question-button" 
              @click="questionToggle(3)"
              >
              <p class="question pr-3">
                ¿Qué documentación tengo que enviar?
              </p>
              <ArrowToggleButton width="19" height="12" :isCollapsed="questions.indexOf(3) !== -1" toggle="collapseThree"/>
            </button>
          </div>
          <div :class="{'show': questions.indexOf(3) !== -1}" class="collapse question-text" >
            <p>
              Estamos a tu disposición por el canal que prefieras. Puedes enviarnos la documentación por correo o WhatsApp, y siempre tendrás nuestro teléfono para consultar dudas.
            </p>
            <p>
              La documentación que necesitamos es la siguiente:
            </p>
            <p class="mb-1 subtitle">
              <b>
                Documentación Personal
              </b>
            </p>
            <ul>
              <li>
                <a href="#" 
                  @click="openModal(
                      'modal_dni',
                      'Parte delantera y trasera de documento de identidad. Sin sombras ni reflejos del flash, sin cortar esquinas y con un fondo blanco ',
                      ['ine2.png','ine1.png']
                    )"
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  INE escaneada por ambos lados
                </a>
              </li>
              <li>
                <a href="#"
                  @click="openModal(
                    'modal_justificante_ingresos',
                    'Nómina del último mes',
                    ['nomina.png']
                  )"
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  Comprobante de ingresos (último recibo de nómina o 2 estados de cuenta)
                </a>
              </li>
              <li>
                <a href="#"
                  @click="openModal(
                      'modal_justificante_ingresos',
                      'Documentos validos: Recibos de Luz, Agua, Gas, Internet, Telefono, Impuesto predial, Estado de cuenta Bancario', ['comprobantes.png']
                    )"
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  Comprobante de domicilio  (máximo 3 meses de antigüedad)
                </a>
              </li>
            </ul>
            <p class="mb-1 subtitle">
              <b>
                Documentación del coche
              </b>
            </p>
            <ul>
              <li>
                <a href="#"
                  @click="openModal(
                    'permiso_circulacion',
                    'Fotografía de factura original por ambos lados',
                    ['factura.webp']
                  )"
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  Fotografía de factura original del coche por ambos lados
                </a>
              </li>
              <li>
                <a href="#"
                  @click="openModal(
                    'permiso_circulacion',
                    'Tarjeta de circulación', 
                    ['circulacion.jpg']
                  )"
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  Tarjeta de circulación
                </a>
              </li>  
              <li>
                <a
                  href="#"
                  @click="
                    openModal(
                      'fotos',
                      'Una fotografía del coche', ['4fotos.webp']
                    )
                  "
                  data-toggle="modal"
                  :data-target="'#' + nombre_modal">
                  Una fotografía del coche
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Q 4 - ¿Puedo liquidar el préstamo en cualquier momento? -->
        <div class="single-question">
          <div class="header" id="headingFour">
            <button 
              class="btn btn-block btn-link text-left d-flex justify-content-between align-items-center question-button" 
              @click="questionToggle(4)"
              >
              <p class="question pr-3">
                ¿Puedo liquidar el préstamo en cualquier momento?
              </p>
              <ArrowToggleButton width="19" height="12" :isCollapsed="questions.indexOf(4) !== -1" toggle="collapseFour"/>
            </button>
          </div>
          <div :class="{'show': questions.indexOf(4) !== -1}" class="collapse question-text">
            <p>
              En Ibancar entendemos que en ciertas situaciones las personas pueden necesitar dinero de manera urgente, pero confían en que podrán resolver su problema de liquidez en los próximos meses. Por eso, ofrecemos la opción de liquidar tu préstamo en el momento que desees, sin ninguna penalización.
            </p>
            <p>
              En Ibancar, nos preocupamos por brindarte opciones flexibles para que puedas adaptar el préstamo a tus necesidades y tener la libertad de liquidarlo cuando mejor te convenga.
            </p>
          </div>
        </div>
        <!-- Q 5 - ¿Cómo se paga el capital de mi préstamo? -->
        <div class="single-question">
          <div class="header" id="headingFive">
            <button 
              class="btn btn-block btn-link text-left d-flex justify-content-between align-items-center question-button" 
              @click="questionToggle(5)"
              >
              <p class="question pr-3">
                ¿Cómo se paga el capital de mi préstamo?
              </p>
              <ArrowToggleButton width="19" height="12" :isCollapsed="questions.indexOf(5) !== -1" toggle="collapseFive"/>
            </button>
          </div>
          <div :class="{'show': questions.indexOf(5) !== -1}" class="collapse question-text" >
            <p>
              En Ibancar comenzarás a pagar el capital e intereses desde el primer pago mensual, y no hay un pago final. Esto significa que siempre pagarás la misma cantidad cada mes.
            </p>
            <p>
              Además, tú eliges la fecha de pago que más te convenga. Te pediremos que nos indiques la fecha que mejor se adapte a tus necesidades, para que puedas realizar el pago en el momento más conveniente para ti.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ModalGenerica :modalName="nombre_modal" :title="title" :img="img_url" />
    </div>
  </div>
</template>
  
<script>
  import ModalGenerica from "@/components/ComoFunciona/ModalGenerica.vue";
  import ArrowToggleButton from '@/components/ArrowToggleButton.vue';
  export default {
    name: "PreguntasFrecuentes",
    components: {
      ArrowToggleButton,
      ModalGenerica
    },
    data() {
      return {
        // questions: new Array(5).fill(true), // isCollapsed => true or false
        name: "",
        nombre_modal: "",
        title: "",
        img_url: null,
        questions: [],
        question: null,
      }
    },
    methods: {
      // questionToggle(position){
      //   const isCollapsed = this.questions[position];
      //   this.questions.fill(true);
      //   this.questions[position] = !isCollapsed;
      // },
      questionToggle: function(position){
        if(this.questions.indexOf(position) === -1){
          this.questions.push(position)
        }else{
          this.questions = this.questions.filter(element => element !== position)
        }
      },
      changeActiveQuestion: function(value){
        console.log(this.question, value)
        this.question = value
      },
      isOneOpen: function(){
        try {
          const flag = this.$refs.collapseOne.className.includes('show');
          console.log(flag)
          return flag;
        } catch (error) {
          return false;
        }
      },
      validateCollapse: function(valueId){
        const element = document.getElementById(valueId);
        console.log(valueId, element)
      },
      goTo(route, hash) {
        this.$router.push({ path: route, hash: hash });
      },
      openModal(nombre, title, img_url) {
        this.nombre_modal = nombre;
        this.title = title;
        this.img_url = img_url;
      },
    }
  };
</script>
  
<style scoped lang="scss">
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  @import '@/assets/styles/variables.scss'; 
  .title{
    font-size: 33px;
    line-height: 57px;
    font-family: 'Montserrat-Bold';
  }
  .text-blue{
    color: $blue;
  }
  .text-green{
    color: $green;
  }
  .text-black{
    color: $black;
  }

  .bold-text{
    font-family: 'Montserrat-Bold';
    font-weight: bold;
  }

  .question{
    font-family: 'Montserrat';
    font-size: 22px;
    color: $black;
    margin-bottom: 0;
    &:hover{
      text-decoration: none!important;
    }
  }

  .question-text{
    font-family: 'Montserrat';
    color: $black;
    padding-left: 1.2em;
    font-size: 18px;
    padding-top: 1rem;
  }
  .subtitle{
    font-family: 'Montserrat';
    color: $black;
    font-size: 16px;
  }

  .questions-container{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .question-button{
    text-decoration: none;
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .single-question{
    border-bottom: solid 1px $gray-2;
    padding: 1rem;
  }

  @include media-breakpoint-down(md){
    .questions-container{
      width: 90%;
    }
    .title{
      font-size: 20px;
      line-height: 25px;
    }
    .subtitle{
      font-size: 16px;
      line-height: 20px;
    }
    .regular-text{
      font-size: 16px!important;
    }

    .question{
      font-size: 18px;
    }

    .question-text{
      font-size: 16px;
    }

    .btn-orange{
      font-size: 16px!important;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    
  }

  @include media-breakpoint-between(md, lg){
    .title{
      font-size: 28px;
      line-height: 35px;
    }
    .subtitle{
      font-size: 20px;
      line-height: 25px;
    }
    .regular-text{
      font-size: 18px!important;
    }

    .question{
      font-size: 20px;
    }

    .question-text{
      font-size: 18px;
    }

    .btn-orange{
      font-size: 18px!important;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
</style>